import { readState } from "@/__main__/app-state.mjs";
import getData from "@/__main__/get-data.mjs";
import {
  QUEUE_SYMBOLS,
  ROLE_SYMBOL_TO_STR,
  ROLE_SYMBOLS,
  SERVICES_TO_REGIONS,
} from "@/game-lol/constants/constants.mjs";
import LoLPlayerChampionMatchupStats from "@/game-lol/models/lol-player-champion-matchup-stats.mjs";
import LoLPlayerChampionStats from "@/game-lol/models/lol-player-champion-stats.mjs";
import LoLSummoner from "@/game-lol/models/lol-summoner.mjs";
import LCUSummoner from "@/game-lol/models/lol-summoner-lcu.mjs";
import * as API from "@/game-lol/utils/api.mjs";
import isLinkedSummoner from "@/game-lol/utils/is-linked-summoner.mjs";
import {
  getLCUAvailable,
  getLCUSummonerProfile,
} from "@/game-lol/utils/lol-client-api.mjs";
import QueueSymbol, {
  getQueueDetails,
  QUEUE_SYMBOL_TO_OBJECT,
} from "@/game-lol/utils/symbol-queue.mjs";
import RoleSymbol from "@/game-lol/utils/symbol-role.mjs";
import {
  extractRegionFromDerivedId,
  getDerivedId,
  getDerivedQueue,
  isPBE,
} from "@/game-lol/utils/util.mjs";
import deepMerge from "@/util/deep-merge.mjs";
import { devError } from "@/util/dev.mjs";

const DEFAULT_QUEUE = getQueueDetails(QUEUE_SYMBOLS.rankedSoloDuo).gql;

async function fetchData(params, searchParam, state) {
  const [region, name, roleStr, id] = params;

  if (isPBE(region)) return;

  if (!region || !name) {
    devError("Missing region or name.");
    return;
  }

  const isUpdate = Boolean(state?.isUpdate);
  const derivedId = getDerivedId(region, name);

  const championId = id && Number(id);
  const roleSymbol = RoleSymbol(roleStr);
  const role = ROLE_SYMBOL_TO_STR[roleSymbol];
  const queue = searchParam?.get("queue") || DEFAULT_QUEUE;
  const queueSymbol = QUEUE_SYMBOL_TO_OBJECT[QueueSymbol(queue)];

  const currentSummoner = readState.volatile.currentSummoner;
  const currentRegion =
    currentSummoner && extractRegionFromDerivedId(currentSummoner);

  const isUnsupportedRegion = !SERVICES_TO_REGIONS[region.toLowerCase()];

  const useLCU =
    isUnsupportedRegion && currentRegion === region && getLCUAvailable();

  if (isUnsupportedRegion && !useLCU) {
    devError("Can not retrieve profile info by any means.");
    return;
  }

  // Extend expiry time for own accounts.
  const isOwnAccount = isLinkedSummoner(readState, region, name);
  const expiryTime = !isOwnAccount
    ? null
    : Date.now() + 1000 * 60 * 60 * 24 * 365; // 1 year

  const profileOptions = {
    expiryTime,
    mergeFn: deepMerge,
  };
  const profilePath = ["lol", "profiles", derivedId];
  const profile = isUnsupportedRegion
    ? await getData(
        () => getLCUSummonerProfile(name),
        LCUSummoner,
        profilePath,
        profileOptions,
      )
    : await getData(
        API.getSummoner({ region, name }),
        LoLSummoner,
        profilePath,
        profileOptions,
      );

  if (!profile || profile instanceof Error) return;

  const promises = [];

  if (!isUnsupportedRegion) {
    promises.push(
      getData(
        API.getPlayerChampionStats({
          region: region.toUpperCase(),
          leagueProfileId: profile.id,
          ...(queue && queue !== getQueueDetails(QUEUE_SYMBOLS.custom).gql
            ? { queue }
            : {}),
        }),
        LoLPlayerChampionStats,
        ["lol", "playerChampionStats", getDerivedQueue(derivedId, queue)],
        { shouldFetchIfPathExists: !isUpdate, expiryTime },
      ),
    );
  }

  if (
    !isUnsupportedRegion &&
    role &&
    roleSymbol !== ROLE_SYMBOLS.all &&
    championId
  ) {
    promises.push(
      getData(
        API.getPlayerChampionMatchupStats({
          leagueProfileId: profile.id,
          region: region?.toUpperCase(),
          queue: queueSymbol.gql,
          role: role.gql,
          championId,
        }),
        LoLPlayerChampionMatchupStats,
        ["lol", "playerChampionMatchups", derivedId, role.gql, championId],
        { skipSafetyCheck: true },
      ),
    );
  }

  return Promise.all(promises);
}

export default fetchData;
